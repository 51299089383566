import React from "react"
import { graphql, navigate } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from '@components/SEO'
import { mapToPageHeader, Banner } from "@components/Banner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { mapToKiwiVIPVisible, SignupBannerWrapper } from "@components/SignupBanner"
import { PaymentService } from "@services/PaymentService"
import { Grid } from "semantic-ui-react"
import { H3, Paragraph1 } from "@styles/Global.styles"
import { LoadingIcon } from "@components/OrderConfirmation/OrderConfirmation.styles"
import { PaymentLinkMessageIcon } from "@components/MakePayment/MakePayment.styles"

interface Props {
  data: any;
  location: any;
}

interface State {
  errorMessage: string,
  loadingPayment: boolean,
}

const queryString = require('query-string');

const loadFromQuerystring = (location: any): { key: string } => {
  const query = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  return {
    key: query.key
  };
}

class SalesRecoveryPage extends React.Component<Props, State>{

  constructor(props: Props) {
    super(props);
    this.state = {
        errorMessage: '',
        loadingPayment: true,
    }
}

  componentDidMount() {
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const key = loadFromQuerystring(this.props.location).key;
    if(key){
      PaymentService.createPaymentRequestForSalesRecovery(apiUrl, key).then((data : any) => {
        if (data != null && data != undefined && data.Success) {4
          navigate("/make-a-payment/?token=" + data.Data)
        } else {
          if(data.Message){
            this.setState({errorMessage:data.Message, loadingPayment:false})
          } else{
            this.setState({errorMessage:"Can't not load payment, please contact our team.", loadingPayment:false})
          }          
        }
      })
    }
    
  }
  render() {
    const { elements } = this.props.data.kontentItemPaymentLinkPage;
    const metadata = mapToPageHeader(elements);
    const seoData = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);    

    return (<Layout version="standard">
      <SEO {...seoData} />
      <Banner {...metadata} isBiggerBanner={false} />
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80
          },
          desktop: {
            top: 80,
            bottom: 100
          }
        }}
        backgroundColor={theme.brand.colors.beige}>
        <div>
          {this.state.loadingPayment &&
              <Grid.Column width={16} textAlign='left'>
                  <H3>
                      Loading Payment
                  <LoadingIcon name='circle notch' size='huge' loading />
                  </H3>
              </Grid.Column>}
          {this.state.errorMessage && 
            <Grid.Column width={16} textAlign='left'>
            <H3>
                <PaymentLinkMessageIcon name='times circle' size='huge' />
                Oops!
            </H3>
            <Paragraph1 dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></Paragraph1>
        </Grid.Column>}
        </div>
      </GenericBannerContainer>
      {
        kiwiVip.visible &&
        <SignupBannerWrapper version={kiwiVip.version} />
      }
    </Layout>
    );
  }
}

export const query = graphql`{
    site {
        siteMetadata {
          apiUrl
        }
    }
    kontentItemPaymentLinkPage {
      elements {
        account_2_account_description {
          value
        }
        credit_card_description {
          value
        }
        afterpay_description {
          value
        }
        general_page_content__header_image {
          value {
            description
            height
            name
            size
            type
            url
            width
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url {
          value
        }
      }
    }    
  }  
`

export default SalesRecoveryPage